import type {
  BoxFragment,
  ReusableContentBoxFragment,
} from '~/graphql/generated'

export function sectionIsBoxRecord(
  section: BoxFragment | ReusableContentBoxFragment,
): section is BoxFragment {
  return section.__typename === 'BoxRecord'
}

export function sectionIsReusableBoxRecord(
  section: BoxFragment | ReusableContentBoxFragment,
): section is ReusableContentBoxFragment {
  return section.__typename === 'ReusableBoxRecord'
}

export function useDatoEvaluateBoxComponents(
  sectionsRef: MaybeRefOrGetter<(BoxFragment | ReusableContentBoxFragment)[]>,
) {
  const sections = toValue(sectionsRef)

  /**
   * Normalized all sections to a single {@link BoxFragment} type
   */
  const boxRecordSections = computed(() =>
    sections.reduce<BoxFragment[]>((accumulator, section) => {
      if (sectionIsBoxRecord(section)) accumulator.push(section)
      else if (
        sectionIsReusableBoxRecord(section) &&
        section.reusableBox?.box?.sections
      )
        accumulator.push(section.reusableBox?.box)
      return accumulator
    }, []),
  )

  return {
    boxRecordSections,
  } as const
}
